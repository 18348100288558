import React from "react";
import "../css/attendence.css";
import { BrowserRouter, NavLink, Route, Routes } from "react-router-dom";

const Attendence = ()=>{
    return(
        <div className={"Attendence"}>
            <p>Es muy importante que firme la asistencia con honestidad</p>
            <h2>Asistencia</h2>
            <NavLink className={"attbuuttons"} style={{background: '#35F25C'}} to={"/atten/in"}>Entrada</NavLink>
            <NavLink className={"attbuuttons"} style={{background: 'red'}} to={"/atten/out"}>Salida</NavLink>
        </div>
    );
}
export default Attendence;