import { useEffect, useState } from "react";
import "../css/week.css";
import { json } from "react-router-dom";

const Week = () => {

    const id = localStorage.getItem('Key001ms');

    const [message, setMessage] = useState(null);

    const weekinfo = async () => {
        //data
        const data = {
            id,
            week: true
        }

        setMessage('Cargando...');

        try {
            //await
            const response = await fetch('https://api.favenllc.com/weekEmployes.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            const result = await response.json();

            //console.log(result);

            if (result.status === 'success') {
                //console.log(result);
                setMessage(result.message);

                result.days.forEach((day, index) => {
                    const noteHoursWeek = document.getElementById('noteHoursWeek');
                    const dayH2 = document.createElement('h3');
                    const hourH2 = document.createElement('h3');
                    const descriptionH2 = document.createElement('h3');
                    const dateH2 = document.createElement('h3');

                    dayH2.textContent = 'Dia: ' + day;
                    hourH2.textContent = 'Horas: ' + result.hours[index];
                    descriptionH2.textContent = 'Descripcion: ' + result.description[index];
                    dateH2.textContent = 'Fecha: ' + result.date[index];
                    dateH2.id = 'subline';

                    noteHoursWeek.appendChild(dayH2);
                    noteHoursWeek.appendChild(hourH2);
                    noteHoursWeek.appendChild(descriptionH2);
                    noteHoursWeek.appendChild(dateH2);
                });
            } else {
                setMessage(result.message);
            }

        } catch (error) {
            console.log('Error en la solicitud de carga al servidor');
        }



    }

    useEffect(() => {

        weekinfo();

    }, []);

    return (
        <div className="week">
            <h2 id="weekh2">Horas de la semana actual</h2>
            <div id="noteHoursWeek">
                <h2 id="noteHoursWeekh2">{message}</h2>
            </div>
        </div>
    );
}

export default Week;