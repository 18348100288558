import { useState } from "react";
import "../css/petition.css";

const Petition = () => {

    const [messages, setMessages] = useState(null); 

    const sendFormPettion = async (e)=>{
        e.preventDefault();

        setMessages('Cargando...');
        document.getElementById('message__').style.display = 'block';

        const issue = document.getElementById('issue').value;
        const message = document.getElementById('message65').value;
        const evidence = document.getElementById('evidence').files[0];
        const id = localStorage.getItem('Key001ms');

        const data = new FormData();

        data.append('issue', issue);
        data.append('message', message);
        data.append('evidence', evidence);
        data.append('id', id);


        try {
            const response = await fetch('https://api.favenllc.com/pettion.php', {
                method: 'POST',
                body: data
            });
            const result = await response.json();
            console.log(result);
            setMessages(result.message);
        } catch (error) {
            console.log('No se pudi enviar la peticion fallo en el servidor');
        }
    }

    return (
        <div className="petition">
            <h2>Peticiones o solicitudes de permiso</h2>
            <form id="formPetition" encType="multipart/form-data" onSubmit={sendFormPettion}>
                {/* issue */}
                <div className="rowForm">
                    <label>Asunto: </label>
                    <input type="text" id="issue" placeholder="Asunto" spellCheck="false" autoComplete="new-password" required></input>
                </div>
                {/* Message */}
                <div className="rowForm">
                    <label>Mensaje: </label>
                    <textarea id="message65" spellCheck="false" placeholder="Deja el mensaje y recuerda escribir para que fecha necesitar el permiso" required></textarea>
                </div>
                {/* Evidence */}
                <div className="rowForm">
                    <label>Evidencia: </label>
                    <input type="file" id="evidence" accept="image/*"></input>
                </div>
                 {/* Submit */}
                 <div className="rowForm">
                    <input type="submit" id="" value={"Enviar"}></input>
                </div>
            </form>
            <h2 id="message__">{messages}</h2>
        </div>
    );
};

export default Petition;
