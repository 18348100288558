import React, { useRef, useEffect, useState } from "react";
import { json, useNavigate, useParams } from "react-router-dom";

const Attenin = () => {
    const paramvalue = useParams();
    const value = paramvalue.paramvalue;
    const navigate = useNavigate();

    const [message, setMessage] = useState(null);

    //Get date
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Mes (0-11)
    const day = String(currentDate.getDate()).padStart(2, '0'); // Día (1-31)
    const hours = String(currentDate.getHours()).padStart(2, '0'); // Horas (0-23)
    const minutes = String(currentDate.getMinutes()).padStart(2, '0'); // Minutos (0-59)
    const seconds = String(currentDate.getSeconds()).padStart(2, '0'); // Segundos (0-59)

    //console.log(year, month, day, hours, minutes, seconds);
    const date = year + '/' + month + '/' + day;
    const time = hours + ':' + minutes;

    const key001ms = localStorage.getItem('Key001ms');

    //console.log(data);

    const success = (pos) => {
        const crd = pos.coords;

        console.log("Your current location is:");
        console.log(`Latitude : ${crd.latitude}`);
        console.log(`Length: ${crd.longitude}`);
        console.log(`Mor or less ${crd.accuracy} metters.`);
        const url = `https://www.google.com/maps?q=${crd.latitude},${crd.longitude}`;
        localStorage.setItem('location', url);
    }

    const error = (err) => {
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    };


    const geoLocation = () => {
        navigator.geolocation.getCurrentPosition(success, error, options);
    }

    const messageOfParams = async () => {
        setMessage('Cargando...');
        let locatiion = localStorage.getItem('location');

        const data = {
            key001ms,
            value,
            date,
            time,
            locatiion
        };
    
        try {
            const response = await fetch('https://api.favenllc.com/sendattendence.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const result = await response.json();
            //console.log(result); 
            setMessage(result.message);
            /*setTimeout(() => {
                navigate('/');
            }, 3000);*/
        } catch (error) {
            console.log('No loaded fetch');
        }
    }


    useEffect(() => {
        const fetchGeoLocation = async () => {
            try {
                await geoLocation(); 
                messageOfParams(); 
            } catch (error) {
                console.error("Error obteniendo la geolocalización:", error);
            }
        };

        fetchGeoLocation();
    }, []);


    return (
        <>
            <h2>{message}</h2>
        </>
    );
}

export default Attenin;
