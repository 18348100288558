import React from "react";
import { useParams } from "react-router-dom";
import Attenin from "./Attein";
import "../../css/atten.css";

const Atten = () => {

    return (
        <div id="atten">
           <Attenin/>
        </div>
    );
}

export default Atten;
