import React, { useState } from "react";
import '../css/login.css';

const Login = () => {

    const [message, setMessage] = useState(null);

    const submit = async (e) => {
        e.preventDefault();
        const user = document.getElementById('user').value;
        const password = document.getElementById('password').value;
        console.log(user, password);

        const data = {
            user,
            password
        };

        document.getElementById('message').style.display = 'block';

        setMessage('Cargando...');

        try {
            const response = await fetch(`https://api.favenllc.com/jwt.php?pwd1=${data.user}&pwd2=${data.password}`);
            const result = await response.json();

            if (result.status === 'success') {
                //console.log(result.Key001ms);
                //To generate key to access 
                localStorage.setItem('Key001ms', result.Key001ms);
                window.location.reload();
            } else {
                console.log(result.status);
                setMessage(result.status);
                setTimeout(()=>{
                    window.location.reload();
                }, 2000);
            }
        } catch (error) {
            console.error('This fetch has failed', error);
            alert('This fetch has failed');
            window.location.reload();
        }
    };

    return (
        <div className="Login">
            <div className="formuser">
                <form onSubmit={submit}>
                    <div className="row"> <label>Usuario</label> <input type="text" id="user" placeholder="Escribir Usuario" spellCheck="false" /> </div>
                    <div className="row"> <label>Contraseña</label> <input type="password" id="password" placeholder="Escribir Contraseña" spellCheck="false" /> </div>
                    <div className="row"> <input type="submit" id="" value="Ingresar" /> </div>
                    <h2 id="message">{message}</h2>
                </form>
            </div>
        </div>
    );
}

export default Login;