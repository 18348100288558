import { useEffect, useState } from "react";
import "../css/changephoto.css";

const ChangePhoto = () => {
    const [message, setMessage] = useState(null);

    const Key001ms = localStorage.getItem('Key001ms');

    const readInformation = async (Key001ms) => {
        const nameUser = document.getElementById('nameUser');
        const userUser = document.getElementById('userUser');
        const passwordUser = document.getElementById('passwordUser');

        const data = {
            readInformation: true,
            Key001ms
        };
        try {
            const response = await fetch('https://api.favenllc.com/changeUserSettings.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            const result = await response.json();

            if (result.status === 'success') {
                nameUser.value = result.name;
                userUser.value = result.user;
                passwordUser.value = result.password;
            } else {

            }

        } catch (error) {
            console.error('Error al enviar al servidor: ', error);
        }

    }

    const sendForm = async (e,) =>{
        e.preventDefault();
        const nameUser = document.getElementById('nameUser').value;
        const userUser = document.getElementById('userUser').value;
        const passwordUser = document.getElementById('passwordUser').value;
        const photoUser = document.getElementById('photoUser').files[0];
        const photoDocument = document.getElementById('photoDocument').files[0];

        const data = new FormData();
        data.append('Key001ms', Key001ms);
        data.append('name', nameUser);
        data.append('user', userUser);
        data.append('password', passwordUser);
        data.append('photoUser', photoUser);
        data.append('photoDocument', photoDocument);
        data.append('updateInfo', true);

        setMessage('Cargando...');

        try {
            const response = await fetch('https://api.favenllc.com/changeUserSettings.php', {
                method: 'POST',
                body: data
            });

            const result = await response.json();
            console.log(result);
            setMessage(result.message);
            

        } catch (error) {
            console.error('an error has ocurried while tried send to server: ',error);
        }
    }


    useEffect(() => {
        readInformation(Key001ms);
    }, []);
    return (
        <div className="changePhoto">
            <h2 id="changeUserSettings">Cambiar ajustes de usuario</h2>
            <form id="changeUserSettingsForm" encType="multipart/form-data" onSubmit={sendForm}>
                {/**/}
                <div className="rowFormchangeUserSettingsForm">
                    <label htmlFor="nameUser">Nombre:</label> <input type="text" id="nameUser" autoComplete="new-password" spellCheck="false" />
                </div>
                {/**/}
                <div className="rowFormchangeUserSettingsForm">
                    <label htmlFor="userUser">Usuario:</label> <input type="text" id="userUser" autoComplete="new-password" spellCheck="false" />
                </div>
                {/**/}
                <div className="rowFormchangeUserSettingsForm">
                    <label htmlFor="passwordUser">Contraseña:</label> <input type="text" id="passwordUser" autoComplete="new-password" spellCheck="false" />
                </div>
                {/**/}
                <div className="rowFormchangeUserSettingsForm">
                    <label htmlFor="photoUser">Foto de prefil:</label> <input type="file" id="photoUser" accept="image/*" autoComplete="new-password" spellCheck="false" />
                </div>
                {/**/}
                <div className="rowFormchangeUserSettingsForm">
                    <label htmlFor="photoDocument" id="labePhotoDocument">foto del documento:</label> <input type="file" id="photoDocument" autoComplete="new-password" accept="image/*" spellCheck="false" />
                </div>
                {/**/}
                <div className="rowFormchangeUserSettingsForm">
                    <input type="submit" id="" value={"Actualizar"} style={{textAlign: "center"}}/>
                </div>
            </form>
            <div id="windowToShowMessageChange">
                <h2>{message}</h2>
            </div>
        </div>
    );
}

export default ChangePhoto;