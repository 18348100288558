import React, { useEffect } from "react";
import "./css/settings.css";
import { NavLink } from "react-router-dom";
import { putInformation } from "./functions/settings";

const Settings = () => {

    //click photo button
    const selectPhoto = (e) => {
        const file = e.target.files[0];
        if (file) {

            const objectURL = URL.createObjectURL(file);
            console.log(objectURL);
            document.getElementById('image').src = objectURL;
        }
    }

    const sendPetition = async (e) => {
        e.preventDefault();
        //loader
        const loader = document.createElement('div');
        loader.className = 'loader';
        //second section
        const secondSection = document.getElementById('secondSection');
        //button submit
        const submit = document.getElementById('submit');

        submit.style.display = 'none';
        secondSection.appendChild(loader);

        setTimeout( async()=>{
            try {
                const img = document.getElementById('imageInput').files[0];
                const formData = new FormData();
    
                if (img) {
                    formData.append('img', img);
                }
    
                formData.append('sendImagePetition', true);
                formData.append('sendText', true);
                formData.append('name', e.target.name.value);
                formData.append('user', e.target.user.value);
                formData.append('password', e.target.password.value);
                formData.append('email', e.target.email.value);
                formData.append('phone', e.target.phone.value);
                formData.append('address', e.target.address.value);
    
                const response = await fetch(`https://api.favenllc.com/sendImageUserPetition.php`, {
                    method: 'POST',
                    body: formData
                });
                const result = await response.json();
                console.log(result);
                if (result.imageUpload) {
                    if (result.imageUpload.status === 'success' && result.textUpload.status === 'success') {
                        loader.style.display = 'none';
                        submit.style.display = 'block';
                        alert('Guardado Exitosamente');
                    } else if (result.textUpload.status === 'success') {
                        loader.style.display = 'none';
                        submit.style.display = 'block';
                        alert('Guardado Exitosamente');
                    } else {
                        alert('Aun no se ha confirmado los cambios por parte de administracion.');
                    }
                } else {
                    if (result.textUpload.status === 'success') {
                        loader.style.display = 'none';
                        submit.style.display = 'block';
                        alert('Guardado Exitosamente');
                    } else {
                        loader.style.display = 'none';
                        submit.style.display = 'block';
                        alert('Aun no se ha confirmado los cambios por parte de administracion.');
                    }
                }
    
    
            } catch (error) {
                console.error(error);
            }
        }, 2000);
    };

    useEffect(() => {
        putInformation();
    }, []);
    return (
        <div className="settings">
            {/*prfile*/}
            <div id="profile">
                {/*bar*/}
                <div id="bar">
                    <NavLink id="back" to={"/"}>Volver</NavLink>
                    <h2 id="tittle">Perfil</h2>
                </div>
                {/*Photo and name*/}
                <div id="PhotoAndName">
                    <img id="image" />
                    <label htmlFor="imageInput" id="imageLabel">Cambiar Foto</label><input type="file" id="imageInput" onChange={selectPhoto} />
                    <h2 id="nameShow" className="shows"></h2>
                    <h2 id="userShow" className="shows"></h2>
                </div>
            </div>
            {/*second section*/}
            <form encType="multipart/form-data" onSubmit={sendPetition}>
                <div id="secondSection">
                    <label htmlFor="">Nombre</label>
                    <input type="text" name="name" id="nameInput" autoComplete="new-password" />
                    <label htmlFor="">Usuario</label>
                    <input type="text" name="user" id="userInput" autoComplete="new-password" />
                    <label htmlFor="">Contraseña</label>
                    <input type="text" name="password" id="passwordInput" autoComplete="new-password" />
                    <label htmlFor="">Email</label>
                    <input type="text" name="email" id="emailInput" autoComplete="new-password" />
                    <label htmlFor="">telefono</label>
                    <input type="text" name="phone" id="phoneInput" autoComplete="new-password" />
                    <label htmlFor="">Direccion</label>
                    <input type="text" name="address" id="addressInput" autoComplete="new-password" />
                    <input type="submit" id="submit" value={"Guardar Cambios"} />
                </div>
            </form>
        </div>
    );
}
export default Settings;