import React, { useEffect, useState } from "react";
import "../css/information.css";

const Information = () => {

    const [message, setMessage] = useState(null);

    const readInfromation = async () => {
        const key001ms = localStorage.getItem('Key001ms');
        //console.log(Key001ms);

        const information = document.getElementsByClassName('information')[0];

        const data = {
            key001ms
        }

        setMessage('Cargando informacion');

        try {
            const response = await fetch('https://api.favenllc.com/information_user.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            const result = await response.json();

            if(result.status === 'success'){
                setMessage('ID: #'+result.id);
                document.getElementById('document_').textContent = 'Documento: '+result.document;
                document.getElementById('email_').textContent = 'Email: '+result.email;
                document.getElementById('phone_').textContent = 'Phone: '+result.phone;
                document.getElementById('range_').textContent = 'Rango: '+result.range;
                document.getElementById('company_').textContent = 'Company: '+result.company;
                document.getElementById('area_').textContent = 'Area: '+result.area;
                document.getElementById('status_').textContent = 'Estado: '+result.statuss;
                document.getElementById('value_').textContent = 'Valor: '+result.value;
                document.getElementById('shift_').textContent = 'Turno: '+result.shift;
            }else{
                //console.log(result);
                setMessage('No se pudo cargar la informacion');
            }
        } catch (error) {
            console.log('No se puedo hacer la cosulta');
            setMessage('No se puedo hacer la cosulta');
        }
    }

    useEffect(()=>{
        readInfromation();
    }, []);
    return (
        <div className="information">
            <h2 id="id_user">{message}</h2>
            <h2 id="document_"></h2>
            <h2 id="email_"></h2>
            <h2 id="phone_"></h2>
            <h2 id="range_"></h2>
            <h2 id="company_"></h2>
            <h2 id="area_"></h2>
            <h2 id="status_"></h2>
            <h2 id="value_"></h2>
            <h2 id="shift_"></h2>
        </div>
    );
}

export default Information;