import React from "react";
import "./main.css";
import Login from "./components/main/Login";
import User from "./components/user/User";
const Main = () => {
    const loadMain = () => {
        if (localStorage.getItem('Key001ms')) {
            return (<User/>);
        }else{
            return(<Login/>);
        }
    }

    return (
        <main>
            {loadMain()}
        </main>
    );
}

export default Main;