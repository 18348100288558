import { useEffect, useState } from 'react';
import './App.css';
import Main from './Main';

function App() {
  const [component, setComponent] = useState(null);

  const verifyVersion = async ()=>{
    if(localStorage.getItem('vesrion')){
      return setComponent(<Main/>);

    }else{
      localStorage.setItem('vesrion', '1.0.0');
      window.location.reload();
    }
  }

  useEffect(()=>{
    verifyVersion();
  },[]);

  return (
    <div className="App">
      {component}
    </div>
  );
}

export default App;

