// funciones de configuración
// función para leer información del usuario
export const informationUser = async (key001ms) => {
    const data = { key001ms };
    try {
        const response = await fetch('https://api.favenllc.com/information_user.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        const result = await response.json();
        console.log(result);
        if (result.status === 'success') {
            document.getElementById('nameShow').textContent = result.name;
            document.getElementById('userShow').textContent = 'Usuario: #'+result.user;
            document.getElementById('nameInput').value = result.name;
            document.getElementById('userInput').value = result.user;
            document.getElementById('passwordInput').value = result.password;
            document.getElementById('emailInput').value = result.email;
            document.getElementById('phoneInput').value = result.phone;
            document.getElementById('addressInput').value = result.address;

            // Guardar el usuario en la sesión
            sessionStorage.setItem('user', result.user);
        } else {
            alert('Error');
        }
    } catch (error) {
        console.error(error);
    }
};

// función para leer la imagen del usuario
export const imageUser = async () => {
    try {
        const user = sessionStorage.getItem('user');
        const response = await fetch(`https://api.favenllc.com/app/Main?imageEmploye=true&id=${user}`, {
            method: 'GET',
        });
        const result = await response.json();
        if (result.status === 'success') {
            document.getElementById('image').src = result.img + '?t' + new Date().getTime();
        }
    } catch (error) {
        console.error(error);
    }
};

// función para poner toda la información en los inputs de configuración
export const putInformation = async () => {
    const key001ms = localStorage.getItem('Key001ms');
    if (key001ms) {
        await informationUser(key001ms);
        imageUser();
    }
};

//function to send image
export const sendImage = async (img)=>{
    const user = sessionStorage.getItem('user');
    const data = new FormData();
    data.append('sendImagePetition', true);
    data.append('img', img); 
    data.append('user', user);
    try {
        const response = await fetch(`https://api.favenllc.com/sendImageUserPetition.php`, {
            method: 'POST',
            body: data
        });

        const result = await response.json();
        console.log(result);
    } catch (error) {
        console.error(error);
    }
}
